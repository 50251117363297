import { Link } from "react-router-dom";
import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { MdOutlineContactSupport } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";


import "../Styles/Terms.css"

export const MembershipTerms = () => {
  return (
    <div>
      <div className="member-terms-container">
        <Link to="/">
          <img
            className="augio-logo-terms-page"
            src="https://augio-other-images.s3.amazonaws.com/color_mock_01.png"
          ></img>
        </Link>

        <div className="member-terms-text">
          <p>
            <p className="member-terms-header">
              Augio Member and Distribution Agreement
            </p>
            <p className="member-terms-subheader">As of 1/1/2024</p>
            <p>
              By creating an account with Augio (the “Platform”) you are
              entering a binding legal agreement (the “Agreement”) between you
              (“You,” “Member,” or “User”) and Augio, LLC (“Augio,” “Us,” or
              “We”), a Florida Limited Liability Company regarding Your use of
              the Augio service to distribute your music to listeners on the
              Platform based on geolocation. (our "Service").{" "}
            </p>
            <p>
              If you are entering into this Agreement on behalf of one or more
              other people, a group, or a company or other entity, then by
              accepting this Agreement you represent and warrant to Augio that
              you are duly authorized to do so on behalf of all such
              person(s)/entity(ies) and to bind them to this Agreement and that
              Augio is fully entitled to rely on that fact (in which case, the
              term "You" includes all such people and entities) in our
              performance under this Agreement.
            </p>
            <p>
              By creating an account, You are accepting the terms and conditions
              below, so please read and understand them completely before doing
              so. Once you do so, this Agreement will automatically become
              effective as of the date the account is created. (the "Effective
              Date").{" "}
            </p>
            <p className="member-terms-subheader">
              {" "}
              Now, therefore, You acknowledge and agree as follows:
            </p>
            <p>
              A.) You are not currently signed to a major record label
              (Universal Music Group, Sony BMG, EMI, and Warner Music Group,
              etc.) or any of their subsidiary labels;
            </p>
            <p>
              B.)You will notify Augio if you become signed to a major record
              label, as described above, or any of their subsidiary labels and
              acknowledge that your music will be subject to removal at that
              time;
            </p>
            <p>
              C.) You will not upload work that is infringing upon another
              person's rights or content, or violates any law, rule, or
              regulation;
            </p>
            <p>
              D.)You agree and acknowledge that You are strictly prohibited from uploading 
            any compositions that are commonly considered “cover songs,” including but not 
            limited to, songs that are created as a derivative work of another artist's 
            original song or songs that mimic the style or arrangement of an existing song.
            </p>
            <p>
              E.) You are agreeing to waive the right to any and all payment and
              grant such licenses to Augio on a limited, worldwide,
              non-exclusive, royalty-free, and fully paid basis, as outlined in
              additional detail below;
            </p>
            <p>
              F.) You agree not to use any service or engage in any activity
              that will alter the streaming numbers, follower count, or any
              other metric used by the platform now or in the future, including
              but not limited to using bots, spamming, or using unauthorized
              third-party tools or promotion;
            </p>
            <p>
              G.) You will not engage in any harassment of Users on the
              Platform, including but not limited to abusive, threatening, and
              defamatory acts.
            </p>
            <p className='member-terms-subheader'>General</p>
            <p className="member-terms-subheader">1. Member's Account</p>
            <p>
              When Member registers for our Service, Member will establish a
              username/artist name and password that will provide access to an
              application-based user dashboard for Members' account on the
              Platform.
              <span className="member-terms-inner-text">
                Member will be solely responsible for any music and other
                content uploaded and for all financial transactions and other
                activity conducted through Members' account. Augio will not be
                responsible for any activity transacted through Member's
                account.
              </span>{" "}
              By signing up for an account with our app, You agree to provide
              Your current zip code and to classify Yourself based on this
              information. You may not attempt to change Your zip code or
              classification for any reason, except in the case of a legitimate
              change of residence. In order to request a change of zip code, You
              must submit a valid government-issued identification showing Your
              new address. By using our app, You agree to comply with all
              applicable laws and regulations, and to provide accurate and
              up-to-date information about Your location. Any attempt to mislead
              or deceive us or Augio users about Your location may result in the
              termination of Your account.
            </p>
            <p className="member-terms-subheader">
              2. The Augio Service and Your Recordings
            </p>
            <p>
              (a) The Augio Service enables you to upload to our servers digital
              files containing audio-only musical sound recordings and the
              underlying musical compositions embodied therein (“collectively,
              "Recordings") for distribution on the Platform which will make
              your Recordings available to other Augio Members ("Customers").
            </p>
            <p>
              (b) Recordings must be musical sound recordings in single track,
              EP, or album configuration.
            </p>
            <p>
              (c) Augio currently accepts Recordings in the following formats:
              AIF, AIFF, FLAC, MP3, M4A, OGG Or WAV, up to 192kHz, 16-bit,
              24-bit, between 1 MB and 1GB in size. Augio may require different
              formats or file sizes from time to time under this Agreement.
              Augio reserves the right to convert audio files of the Recordings
              as necessary.{" "}
            </p>
            <p>
              (d) When uploading each Recording, you must provide us with all
              accompanying data that we may require (e.g., artist name, album
              title, each track title, genre), plus any available cover art (in
              JPG format with RGB color or any other format that we may
              require). For purposes of this Agreement, all such data, metadata,
              information, image files, artwork, and any other materials you
              provide to us are included in the definition of "Recordings." We
              may also require that you indicate if the Recording contains
              explicit content, in which case we may tag it accordingly.
            </p>
            <p>
              (e) Once Member has uploaded a recording to the Site for
              distribution, Member can delete it at any time during the Term.
              However, Member cannot remove a single track that was included as
              part of an entire album. If Member wants to remove a single track
              from an album, Member must remove the entire album and re-upload
              the entire album with the removed track(s).
            </p>
            <p className="member-terms-subheader">3. Altering Metrics Augio</p>
            strictly prohibits any attempts to alter or manipulate the metrics
            provided on our platform. This includes, but is not limited to,
            employing techniques such as the use of bots, botnets, scripts,
            apps, plugins, extensions, or other means to misrepresent the
            popularity of uploads on the Augio platform, or to misrepresent
            activity on the platform. Any attempt to engage in such activities,
            whether successful or not, will result in immediate termination of
            Your account without notice or warning. In addition, Augio will not
            be held liable for any damages or losses that may result from Your
            attempts to alter metrics or engage in prohibited activities on the
            platform. By accessing and using the Augio platform, You agree to
            adhere to these terms and any violations will result in the
            termination of Your account.
            <p className="member-terms-subheader">
              4. Service and Intellectual Property
            </p>
            <p>
              Ownership Augio does not take any copyright or other interest in
              any of your music, only a limited license to distribute. Users are
              permitted only to upload content in which they are the rightful
              owner or have received authorization to distribute on the Augio
              platform. Users are not allowed, and it is a violation of United
              States Copyright laws to use and/or distribute another’s content
              without consent from the rightful owner.
            </p>
            <p className="member-terms-subheader">5. Member Responsibilities</p>
            Member is solely responsible for and must have obtained all
            necessary rights, licenses, waivers, clearances, and permissions,
            including without limitation all music publishing rights and
            licenses, for all Recordings (including for purposes of clarity the
            Compositions) and other Materials in order to enable Augio to fully
            exploit all their rights hereunder free of any claims, liens,
            encumbrances or other restrictions. Member, through uploading any
            Recordings and delivery of any other Materials, warrants and
            represents to Augio to sell, distribute, publicly perform, promote,
            and otherwise exploit such Recordings and Materials as contemplated
            under this Agreement, including the waiver of all so-called “moral
            rights”, under the laws of any jurisdiction, on your behalf as well
            as on behalf of any and all contributors involved in any manner with
            the creation and delivery of your Recordings.
            <p className="member-terms-subheader">
              6. Royalty Waiver and Grant of Rights
            </p>
            By accessing and using Augio, Users hereby represent and warrant to
            the extent Users are the songwriter of any or all of the musical
            works embodied in their User Content, whether in whole or in part
            (e.g., as a co-writer), You have the full right, power, and
            authority to grant the rights set forth in this Agreement
            notwithstanding the provisions of any agreement You may have entered
            into with any Performing Rights Organization (“PRO”), whether based
            in the United States or elsewhere, or any music publisher, and that
            the User is solely responsible for taking all steps necessary to
            inform such PRO or music publisher of the grant of a royalty free
            license to Augio and Our Licensees for the public performance and
            communication to the public of the User Content, and that no fees or
            payments of any kind whatsoever shall be due to any PRO or music
            publisher for the use of the musical works in the User Content when
            publicly performed, communicated or otherwise transmitted by Augio
            or its Licensees.
            <p>
              Further, by uploading User Content to the Platform, Users grant a
              limited, worldwide, non-exclusive, royalty-free, fully paid up,
              license to other Users of the Platform, and to operators and Users
              of any other websites, apps and/or platforms to which User Content
              has been shared or embedded, to use, copy, listen to offline,
              repost, transmit or otherwise distribute, publicly display,
              publicly perform, adapt, prepare derivative works of, compile,
              make available and otherwise communicate to the public, User
              Content that utilizes the features of Augio and within the
              parameters set by You using the Platform. By uploading User
              Content to the Platform, Users initiate an automated process to
              transcode any audio Content and direct Augio to store User Content
              on the servers, from where You may control and authorize the use,
              ways of reproduction, transmission, distribution, public display,
              public performance, making available (including whether Users will
              be permitted to listen to Your Content offline) and other
              communication to the public of User Content on the Platform and
              elsewhere using the Services. To the extent it is necessary in
              order for Augio to provide Users with any of the aforementioned
              hosting services, to undertake any of the tasks set forth in these
              Terms, including the distribution of advertising or other
              promotional material on Our Platform and/or to enable Your use of
              the Platform, You hereby grant such licenses to Augio on a
              limited, worldwide, non-exclusive, royalty-free, and fully paid
              basis.
            </p>
            <p>
              In order for Augio to distribute your Recordings and related
              content throughout the Platform via the Augio service, You confirm
              that you grant Augio the non-exclusive, sub-licensable right and
              license during the Term throughout the Territory to:
            </p>
            <p>
              i.) Reproduce and distribute your Recordings to Users on the
              Platform by any and all applicable digital formats,
              configurations, technologies and methods (including, without
              limitation, permanent downloads, temporary or "tethered" download,
              interactive and non-interactive streaming, "scan and match"
              services, and "cloud" services) to any and all capable devices
              (including, without limitation, to personal and tablet computers
              and smartphones),
            </p>
            <p>
              ii.) Display and otherwise use your artist(s) and/or non-major
              label name and logo and all artwork, song and album titles,
              trademarks, service marks, logos, and any associated trade names.
              This also includes all artist, songwriter, producer and mixer
              names and likenesses, each as found in the metadata within any
              Recordings or other material provided by you (“Materials”), on our
              Platform, including any marketing, advertising, or promotional
              materials for our Service;
            </p>
            <p>
              iii.) Notify any and all related third parties of Augio's rights
              and the relationship between You and Augio under this Agreement,
              as well as listing You as a licensor of the Augio Service;{" "}
            </p>
            <p>
              iv.) You also grant to Augio the right and license during the Term
              and throughout the Territory to take all steps desired or required
              to effect the foregoing rights and to distribute your Recordings
              and Materials as contemplated in this Agreement, including without
              limitation, to store, host, cache, reproduce, convert, edit,
              serve, transmit and publicly perform such Recordings, as may be
              amended from time to time during the Term. We may decline to
              distribute or otherwise exploit any Recordings or other Materials
              (or to remove any Recordings that have already been distributed or
              offered to Customers) in our reasonable business judgment. You
              will be deemed to have approved any artwork, photographs,
              biographical material or other information or materials that you
              provide to us.
            </p>
            <p className="member-terms-subheader">
              WE DO NOT AND CANNOT PROVIDE YOU WITH LEGAL ADVICE REGARDING YOUR
              OBLIGATIONS TO THIRD PARTIES, SO PLEASE CONSULT A QUALIFIED LAWYER
              BEFORE ENTERING INTO THIS AGREEMENT AND UPLOADING ANY RECORDINGS
              TO OUR SERVICE.
            </p>
            <p className="member-terms-subheader">
              PLEASE UNDERSTAND THAT YOU MUST OWN OR OTHERWISE HAVE THE LEGAL
              RIGHT TO REPRODUCE AND DISTRIBUTE 100% OF THE RECORDINGS, MUSICAL
              COMPOSITIONS, ARTWORK AND ANY OTHER MATERIAL THAT YOU INTEND TO
              UPLOAD AND DISTRIBUTE VIA THE AUGIO SERVICE, INCLUDING THE RIGHT
              TO MAKE AND DISTRIBUTE DIGITAL DOWNLOADS EMBODYING THE MUSICAL
              COMPOSITIONS THEREIN, AND ELECTRONIC TRANSMISSIONS OF SUCH MUSICAL
              COMPOSITIONS (INCLUDING, WITHOUT LIMITATION, VIA STREAMING
              SERVICES), AS NECESSARY. FOR EXAMPLE, YOU CANNOT DELIVER TO US ANY
              REMIXES, OR RECORDINGS THAT INCLUDE SAMPLES UNLESS YOU HAVE ALL
              NECESSARY WRITTEN PERMISSION FROM THE SONGWRITERS AND FROM THE
              OWNERS OF THE APPLICABLE ORIGINAL RECORDINGS.
            </p>
            <p className="member-terms-subheader">7. Term and Termination</p>
            <p>
              Augio retains the right to terminate this agreement for any
              reason, with or without cause, including but not limited to if we
              reasonably believe that You or any of Your Recordings or any other
              content has violated this Agreement or any other agreement your
              use may be subject to.
            </p>
            <p>
              In addition to content violations, Augio reserves the right to
              terminate this agreement and remove You from the Platform if it is
              found that You have signed with a major record label or one of
              their subsidiary labels, as described above.
            </p>
            <p className="member-terms-subheader">8. Payment</p>
            <p>
              For use of Our Services, at times, Augio may accept payment via
              PayPal or by Credit/Debit card. If We offers paid-for services or
              subscription options, a notification will be sent to all Users via
              the email associated with the User account.{" "}
            </p>
            <p className="member-terms-subheader">
              9. Representations and Warranties You represent and warrant to
              Augio that:
            </p>
            <p>
              i.) You have the legal capacity, right and authority to enter into
              this Agreement;{" "}
            </p>
            <p>
              ii.) You own fully or are otherwise fully vested of the necessary
              copyrights and any other rights in order to provide a grant of
              rights, licenses and permissions herein to Augio, and our
              respective permitted successors and assigns shall not violate or
              infringe any applicable law, rule or regulation or the rights of
              any third party;
            </p>
            <p>
              iii.) You have secured all third-party grants of rights, consents,
              licenses and permissions necessary, including without limitation
              mechanical licenses from copyright owners or proprietors, to grant
              all rights and licenses and otherwise fully perform all
              obligations hereunder, and shall make all payments and otherwise
              comply with all of your obligations under this Agreement; iv. Your
              Recordings and Materials do not and will not, and the authorized
              use thereof by Augio, shall not infringe any copyright, trademark
              or other intellectual property or other rights, including but not
              limited to, rights of publicity, privacy or moral rights, of any
              third party, or violate any applicable treaty, statute, law,
              order, rule or regulation; v. the files containing the Recordings
              and Materials that you upload or otherwise provide to us do not
              and will not contain any bugs, viruses, trojan horses or other
              defects or harmful elements or digital rights management
              restrictions;
            </p>
            <p>
              vi.) all data, metadata, and information provided by you under or
              in connection with this Agreement is and will be true, accurate
              and complete, and you agree to update the same promptly as
              necessary during the Term;
            </p>
            <p>
              vii.) You will use the Site and Service only in accordance with
              this Agreement and not for any fraudulent, infringing or
              inappropriate purposes;
            </p>
            <p>
              viii.) any sale, assignment, transfer, mortgage or other grant of
              rights in or to your interest in any of the Recordings or
              Materials shall be subject to our rights hereunder and the terms
              and conditions hereof;
            </p>
            <p>
              ix.) there are not any actual or threatened claims, litigation,
              administrative proceedings regarding any Recordings or Materials
              that would prevent full exploitation of all rights and licenses
              granted hereunder;
            </p>
            <p>
              x.) there is not any existing agreement, and you will not enter
              into any agreement or perform any act, which materially interferes
              or is inconsistent with the rights granted to us hereunder;
            </p>
            <p>
              xi.) You have read and understand this Agreement and have had the
              opportunity to consult with independent legal counsel in
              connection with them.
            </p>
            <p className="member-terms-subheader">10. Indemnification</p>
            <p>
              You shall indemnify and hold harmless, and upon our request,
              defend, Augio and our affiliates, successors and assigns, and the
              respective directors, officers, shareholders, members, managers,
              employees, agents and representatives of the foregoing, from and
              against any and all claims, suits, proceedings, disputes,
              controversies, losses, liabilities, damages, costs and expenses
              (including reasonable attorneys' fees and costs) resulting from:
            </p>
            <p>
              i.) A breach or alleged breach of any of your warranties,
              representations, covenants or obligations under this Agreement;
            </p>
            <p>
              ii.) any claim that the Recordings, Materials, data or information
              provided or authorized by You or on your behalf hereunder or the
              use thereof by Augio violates or infringes the rights of another
              party;{" "}
            </p>
            <p>
              iii.) any other act or omission by you or any of your licensors,
              agents or representatives. You will promptly reimburse Augio and
              any other indemnified parties on demand for any amounts subject to
              indemnification. We shall notify you of any such claim and shall
              control the defense thereof, though you may participate in such
              defense at your own expense. You may not settle any claim for
              which we may be liable without our prior written consent, which we
              will not withhold unreasonably. If any facts, claims, proceedings
              or other circumstances arise that would be subject to
              indemnification, then Augio, in addition to any other right or
              remedy, shall have the right to withhold from any payments
              otherwise due to you an amount reasonably related thereto until
              the claim, proceeding or circumstance has been finally resolved,
              settled or fully adjudicated and the judgment satisfied, or that
              the statute of limitations on such claim has run, or when you have
              provided reasonable and adequate security for the claim. Augio
              reserves the right to charge you (or deduct from monies payable to
              you) for any legal fees incurred by Augio as a result of your
              violation of this Agreement.
            </p>
            <p className="member-terms-subheader">
              11. No Warranty; Limitations of Liability
            </p>
            <p>
              THE SITE AND SERVICE ARE OFFERED AND PROVIDED TO YOU AS-IS. AUGIO
              MAKES NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, EXPRESS OR
              IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION AS
              TO THE AMOUNT OF INCOME THAT MAY BE EARNED BY OR PAYABLE TO YOU
              HEREUNDER, OR AS TO THE CONDITION, QUALITY, CONTINUITY OF
              OPERATION, PERFORMANCE, MERCHANTABILITY OR FITNESS FOR A
              PARTICULAR PURPOSE OF OUR SITE OR SERVICE. WE DO NOT GUARANTEE
              THAT ACCESS TO OR USE OF THE SITE OR SERVICE WILL BE CONTINUOUS,
              UNINTERRUPTED, ERROR-FREE OR SECURE. ANY AND ALL WARRANTIES ARE
              EXPRESSLY DISCLAIMED AND EXCLUDED. TO THE MAXIMUM EXTENT PERMITTED
              BY LAW, NEITHER AUGIO NOR ANY OF ITS AFFILIATES, EMPLOYEES,
              OWNERS, EMPLOYEES, REPRESENTATIVES OR AGENTS WILL BE LIABLE FOR
              ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR
              CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF
              PROFITS, LOST SALES, LOSS OF DATA OR LOSS OF GOODWILL, OR FOR YOUR
              USE OF OR ACCESS TO THE SITE OR SERVICE, WHETHER BASED ON
              WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY
              OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN
              INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED
              REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
              PURPOSE. IN NO EVENT WILL AUGIO'S AGGREGATE LIABILITY ARISING OUT
              OF OR IN CONNECTION WITH THIS AGREEMENT EXCEED THE SUMS ACTUALLY
              DUE TO YOU IN ACCORDANCE WITH PARAGRAPH 10 ABOVE. THE LIMITATIONS
              OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS
              OF THE AGREEMENT BETWEEN AUGIO AND YOU.
            </p>
            <p className="member-terms-subheader">12. Miscellaneous</p>
            <p>
              This Agreement, together with the Privacy Policy and Terms of
              Service, contains the parties' entire understanding and supersedes
              any prior or contemporaneous correspondence, agreements or
              understandings regarding the subject matter herein. We may amend
              the terms of this Agreement from time to time, in which case we
              will notify you by changing the date at the top of this Agreement
              (so please be sure to check back often) and/or via e-mail or by
              notice to your dashboard on the Site. You must terminate your
              account if you do not agree to the revised Agreement; your
              continued use of the Site or Service will be deemed your
              acceptance. A party's waiver of a breach of any provision of this
              Agreement by the other shall not operate or be construed as a
              waiver of any subsequent breach of the same provision or any other
              provision of this Agreement. If any provision hereof is found
              invalid or unenforceable pursuant to judicial decree or decision,
              the remainder of this Agreement shall remain valid and enforceable
              according to its terms, such determination shall not affect any
              other provision hereof, and the unenforceable provision shall be
              limited solely as necessary or replaced by an enforceable
              provision that most closely meets the commercial intent of the
              parties. Augio will not be liable for a curable breach of this
              Agreement unless you provide us with written notice specifying the
              alleged breach that we confirm receipt of, and we fail to cure
              such breach within ninety (90) days thereafter. We may direct all
              notices and communications to you via the email address or street
              address associated with your account and/or via your dashboard
              account on the Site. All notices to Augio shall be sent to us at
              both support@Augio.io and legal@Augio.io. You may not assign,
              transfer or delegate any of your rights or obligations hereunder
              without our prior written consent, and any purported attempt
              otherwise will be null and void ab initio. We may assign,
              delegate, pledge, encumber, sublicense and otherwise transfer,
              this Agreement and/or any or all of our rights and obligations in
              order to operate the Service and Site. This Agreement will be
              binding on and inure to the benefit of the parties and their
              respective assigns and successors in interest.
            </p>
            <p>
              The relationship between the parties is that of independent
              contractors. This Agreement shall not be deemed to create a
              partnership or joint venture, and neither party is the other's
              agent, partner, employee, or representative.
            </p>
            <p>
              This Agreement shall be construed and enforced exclusively in
              accordance with the laws of the State of New York, without regard
              to its conflict of laws principles. Any action or proceeding
              brought by either party against the other arising out of or
              related to this Agreement shall be brought only in a state or
              federal court of competent jurisdiction located in the State of
              New York, and the parties irrevocably consent to the in personam
              jurisdiction and venue of said courts. You agree that service of
              process by us to you by one of the methods designated above for
              the giving of notices will be deemed effective as personal service
              within New York, and hereby consent to service of process by such
              methods.
            </p>
            <p className="member-terms-subheader">
              By acknowledging this Agreement, You are also agreeing to Augio's
              Terms of Service which can be found&nbsp;
              <Link className='terms-service-link'to="/terms">Here</Link>
            </p>
          </p>
        </div>
        <footer>
        <div className="footer-entire-group">
          <div className="footer-links-group">
            <h6>Company</h6>
            <p className="footer-link">
              <HiOutlineMail className="footer-icon" />
              Press: info@augio.io
            </p>
            <p className="footer-link">
              <MdOutlineContactSupport className="footer-icon" />
              Support: support@augio.io
            </p>
          </div>
          <div className="footer-links-group">
            <h6>Quick Links</h6>
            {/*} <div className="footer-social-text footer-text-spacing">Contact</div>*/}
            <Link className="footer-link clickable-link"target="_blank" to="/terms">
              Terms
            </Link>
            <Link className="footer-link clickable-link" target="_blank" to="/membership-terms">
              Membership Agreement
            </Link>

            <Link className="footer-link clickable-link" target="_blank" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <div className="footer-links-group">
            <h6>Social</h6>

            <div className="social-link-row">
              <a
                className="footer-link clickable-link"
                href="https://www.twitter.com/augiomusic_io"
                target="_blank"
              >
                <BsTwitter className="footer-icon"></BsTwitter>
              </a>
              <p className="social-link-name">Twitter</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.instagram.com/augio.io"
                target="_blank"
              >
                <AiFillInstagram className="footer-icon"></AiFillInstagram>
              </a>
              <p className="social-link-name">Instagram</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.tiktok.com/@augio.io"
                target="_blank"
              >
                <FaTiktok className="footer-icon"></FaTiktok>
              </a>
              <p className="social-link-name">TikTok</p>
            </div>
          </div>
        </div>
        <div className="augio-copyright">© 2024 AUGIO LLC</div>
      </footer>
      </div>
    </div>
  );
};
