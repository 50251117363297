import { firestore } from "../firebase";
import {
    collection,
    doc,
    setDoc,
    serverTimestamp,
    query,
    where,
    getDocs,
    getDoc,
    deleteDoc,
    updateDoc,
    DocumentData,
    orderBy
} from "firebase/firestore";
import { Track } from "../Types/Types";

export class PlaylistService {

    static async createPlaylist(userId: string, playlistName: string) {
        try {
            const sanitizedPlaylistUrlName = playlistName
                .toLowerCase() 
                .trim() 
                .replace(/\s+/g, ''); 
            const playlistDocRef = doc(collection(firestore, "playlists"));
            const playlistData = {
                playlistName: playlistName,
                playlistUrlName: sanitizedPlaylistUrlName,
                dateCreated: serverTimestamp(),
                userId: userId,
            };
            await setDoc(playlistDocRef, playlistData);
            return {
                id: playlistDocRef.id,
                playlistName,
                playlistUrlName: sanitizedPlaylistUrlName,
            };
        } catch (error) {
            console.error("Error creating playlist: ", error);
            throw error;
        }
    }


    static async deletePlaylist(playlistId: string) {
        try {
            const playlistDocRef = doc(firestore, "playlists", playlistId);
            await deleteDoc(playlistDocRef);
        } catch (error) {
            console.error("Error deleting playlist: ", error);
            throw error;
        }
    }

    static async editPlaylist(playlistId: string, playlistName: string) {
        try {
            const sanitizedPlaylistUrlName = playlistName
                .toLowerCase() 
                .trim() 
                .replace(/\s+/g, ''); 
            const playlistDocRef = doc(firestore, "playlists", playlistId);
            const updatedData = {
                playlistName: playlistName, 
                playlistUrlName: sanitizedPlaylistUrlName, 
            };
            await updateDoc(playlistDocRef, updatedData);
        } catch (error) {
            console.error("Error editing playlist: ", error);
            throw error;
        }
    }

    static async fetchPlaylists(userId: string) {
        try {
            const playlistsQuery = query(
                collection(firestore, "playlists"),
                where("userId", "==", userId)
            );
            const querySnapshot = await getDocs(playlistsQuery);
            const playlists = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                playlistName: doc.data().playlistName,
            }));
            return playlists;
        } catch (error) {
            console.error("Error fetching playlists: ", error);
            throw error;
        }
    }


    static async fetchPlaylist(playlistId: string) {
        try {
            const playlistDocRef = doc(firestore, "playlists", playlistId);
            const playlistDoc = await getDoc(playlistDocRef);

            if (!playlistDoc.exists()) {
                console.error("Playlist not found");
                return null;
            }

            const playlistData = playlistDoc.data();
            const userId = playlistData?.userId;
            const playlistName = playlistData?.playlistName; 

            const userDocRef = doc(firestore, "users", userId);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                console.error("User not found");
                return null;
            }

            const userName = userDoc.data()?.userName;

            return {
                id: playlistDoc.id,
                playlistName, 
                userName, 
            };
        } catch (error) {
            console.error("Error fetching playlist:", error);
            throw error;
        }
    }

   


    static async fetchPlaylistTracks(playlistId: string) {
        try {
            const tracksCollectionRef = collection(
                doc(firestore, "playlists", playlistId),
                "playlistTracks"
            );

            const tracksQuery = query(
                tracksCollectionRef,
                orderBy("dateAdded", "desc")
            );

            const querySnapshot = await getDocs(tracksQuery);
            const trackIds = querySnapshot.docs.map((doc) => doc.data().trackId);

            return trackIds;
        } catch (error) {
            console.error("Error fetching playlist tracks: ", error);
            throw error;
        }
    }


    static async fetchTracksDetails(trackIds: string[]): Promise<Track[]> {
        if (trackIds.length === 0) {
            return []; 
        }

        try {
            const tracksCollectionRef = collection(firestore, "tracks");
            const tracksQuery = query(
                tracksCollectionRef,
                where("__name__", "in", trackIds)
            );
            const querySnapshot = await getDocs(tracksQuery);

            const tracks: Track[] = querySnapshot.docs.map((doc) => {
                const trackData = doc.data() as DocumentData;
                return {
                    id: doc.id,
                    trackTitle: trackData.trackTitle,
                    userName: trackData.userName,
                    artistName: trackData.artistName,
                    genre: trackData.genre,
                    coverArtUrl: trackData.coverArtUrl,
                    chartPosition: trackData.chartPosition,
                    releaseDate: trackData.releaseDate?.toDate ? trackData.releaseDate.toDate() : trackData.releaseDate,
                    audioFileUrl: trackData.audioFileUrl,
                    featureArtists: trackData.featureArtists,
                    producers: trackData.producers,
                    songwriters: trackData.songwriters,
                    engineers: trackData.engineers
                };
            });

            return tracks;
        } catch (error) {
            console.error("Error fetching track details:", error);
            throw error;
        }
    }


    static async addTrackToPlaylist(playlistId: string, trackId: string) {
        try {
            const tracksCollectionRef = collection(
                doc(firestore, "playlists", playlistId),
                "playlistTracks"
            );
            await setDoc(doc(tracksCollectionRef, trackId), {
                trackId,
                dateAdded: serverTimestamp(), 
            });
        } catch (error) {
            console.error("Error adding track to playlist:", error);
            throw error;
        }
    }

}
