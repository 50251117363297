import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Styles/Terms.css"
import { MdOutlineContactSupport } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";





export const Terms = () => {
  return (
    <div>
      <div className="terms-container">
        <Link to="/">
          <img
            className="augio-logo-terms-page"
            src="https://augio-other-images.s3.amazonaws.com/color_mock_01.png"
          ></img>
        </Link>

        <div className="inner-text">
          <h4 className="terms-header">Terms & Conditions</h4>
          <p>

              <p className="terms-subtext">
                Welcome to Augio, a platform that provides digital music
                streaming for listeners based on geolocation. Augio is owned and
                operated by Augio, LLC, along with licensees and assignees
                (hereafter referred to as “Augio” “Us” “We” or any other
                first-person pronoun). All individuals or entities that access
                the Site are hereafter referred to as “You” or “User.”
              </p>
              <p className="terms-subtext">
              These terms and conditions (“Terms”) govern Your use of and access
              to Augio's content, streaming services, platforms, including
              website and mobile application and streaming services, and any
              additional associated websites and applications (the “Service”).
              These Terms are effective as of the Effective Date listed above.
              Augio may modify these Terms on occasion and will notify Users of
              such changes by any reasonable means, including (where
              appropriate) by posting an amended version of these Terms
              (“Amended Terms”). Any such changes will not apply to any dispute
              between You and Augio arising prior to the date the changes were
              made. Your continued use of the Service after any updates to the
              Terms will constitute Your acceptance of the Amended Terms. If You
              wish to cease use of the Service under any Amended Terms, You may
              cancel Your subscription as set forth herein. The Terms, along
              with any Amended Terms and shall be collectively referred to as
              the “Agreement”.</p>
              
              <p className="terms-subtext">For information on how Your data may be used and
              collected, please review the <Link
                to="/privacy-policy"
                className="copyrightinfringement-link"
              >
                Privacy Policy
              </Link>.</p> 
              
              <p className="terms-subtext">Your subscription to and/or use of the Service constitutes Your
              acknowledgement that You have (1) fully read and understand the
              Agreement, (2) read Our Privacy Policy, and (3) accepted the terms
              of the Agreement and Privacy Policy.</p>


              <p className="terms-subheader">What is Augio?</p> 
              
              <p className="terms-subtext">Augio is a digital music streaming platform
              for independent artists that will organize charts for listeners
              based on Artist's geographical location. Artists are Users who
              upload music to the Augio Platform for listening and streaming
              purposes for other Users. Augio strives to set itself apart from
              other digital music streaming platforms by highlighting Artists
              who are not signed to a major label (Universal Music Group, Sony
              BMG, EMI, and Warner Music Group, etc.) or any other their
              subsidiary labels. </p>

              <p className="terms-subheader">User Account, Information, Content and Permissions</p>
              <p className="terms-subheader">Joining Augio</p> 
              
              <p className="terms-subtext">All Augio Users must register for an Augio
              account. When registering for an account, Users must provide the
              following information:
              <ul>
              <li>Legal Name (first and last)</li>
              <li>Email Address</li>
              <li>Location - State</li>
              <li>Username</li>
              <li>User Type</li>
              <li>Password</li>
    
              </ul>
              It is the
              sole responsibility of the User to maintaining the confidentiality
              and security of usernames and passwords, and Users will remain
              responsible for all use of Your username and password, and all
              activity emanating from the account, whether or not such activity
              was authorized by the User. Upon a username or password being
              forgotten or stolen, Users may use the “Forgot Username” or
              “Forgot Password” option on the homepage of the Platform or
              contact support@augio.io for assistance with recovery.</p>


              <p className="terms-subheader">User Permissions</p> 
              
              <p className="terms-subtext">Augio acts as an intermediary between
              Artist's and Listeners and connects Artists with those who may be
              interested in creating a partnership. Thus, by creating an Augio
              Account and acknowledging these Terms, all Users authorize Augio
              to share any information We acquire from Your use of the Platform
              with other Users on the Platform and Our third-party partners. In
              addition to the ability for Users to directly upload and listen to
              music, Augio offers a variety of social and interactive features
              which allows Users to connect with one another. Users may like,
              comment, subscribe, and chat with other Users. Please note that
              any content uploaded and posted to Augio by Users will be stored
              by Augio and may be shared and distributed by other Users on the
              Platform. Augio Artists Artists on Augio may receive date and
              information in relation to the music they upload. Artists may also
              receive data, statistics, general location, and other insights on
              their Listeners and how Listeners are interacting with the
              Artist's content.</p>
              <p className="terms-subheader">User Content</p> 
              
              <p className="terms-subtext">Any and all audio, lyrics, pictures, artwork,
              comments, and other content, data, or information that Users
              upload, store, transmit, submit, exchange or make available to or
              via Augio ("User Content") is generated, owned and controlled
              solely by the User, and not by Augio. Augio does not claim any
              ownership rights in User Content, and You hereby expressly
              acknowledge and agree that the User Content You submit, and upload
              remains Your sole responsibility. Users must not store,
              distribute, send, transmit, display, perform, make available,
              continue to make available or otherwise communicate to the public
              any Content to which You do not hold the necessary rights. In
              particular, any unauthorized use of copyright protected works
              within User Content (17 U.S.C. § 106 outlines the exclusive rights
              of copyright owners, including reproduction, distribution,
              modification, adaptation, public display, public performance,
              preparation of derivative works, making available or otherwise
              communicating to the public via the Platform), independent of
              whether it is or becomes unauthorized at a later point, may
              constitute an infringement of third party rights and is strictly
              prohibited. Any such infringements may result in termination of
              Your access to Augio and may also result in civil litigation or
              criminal prosecution by or on behalf of the relevant rightsholder.</p>

              <p className="terms-subheader">License Grant to Augio</p> 
              
              <p className="terms-subtext">By accessing and using Augio, Users
              hereby represent and warrant to the extent Users are the
              songwriter of any or all of the musical works embodied in their
              User Content, whether in whole or in part (e.g., as a co-writer),
              You have the full right, power, and authority to grant the rights
              set forth in this Agreement notwithstanding the provisions of any
              agreement You may have entered into with any Performing Rights
              Organization (“PRO”), whether based in the United States or
              elsewhere, or any music publisher, and that the User is solely
              responsible for taking all steps necessary to inform such PRO or
              music publisher of the grant of a royalty free license to Augio
              and Our Licensees for the public performance and communication to
              the public of the User Content, and that no fees or payments of
              any kind whatsoever shall be due to any PRO or music publisher for
              the use of the musical works in the User Content when publicly
              performed, communicated or otherwise transmitted by Augio or its
              Licensees. Further, by uploading User Content to the Platform,
              Users grant a limited, worldwide, non-exclusive, royalty-free,
              fully paid up, license to other Users of the Platform, and to
              operators and Users of any other websites, apps and/or platforms
              to which User Content has been shared or embedded, to use, copy,
              listen to offline, repost, transmit or otherwise distribute,
              publicly display, publicly perform, adapt, prepare derivative
              works of, compile, make available and otherwise communicate to the
              public, User Content that utilizes the features of Augio and
              within the parameters set by You using the Platform. By uploading
              User Content to the Platform, Users initiate an automated process
              to transcode any audio Content and direct Augio to store User
              Content on the servers, from where You may control and authorize
              the use, ways of reproduction, transmission, distribution, public
              display, public performance, making available (including whether
              Users will be permitted to listen to Your Content offline) and
              other communication to the public of User Content on the Platform
              and elsewhere using the Services. To the extent it is necessary in
              order for Augio to provide Users with any of the aforementioned
              hosting services, to undertake any of the tasks set forth in these
              Terms, including the distribution of advertising or other
              promotional material on Our Platform and/or to enable Your use of
              the Platform, You hereby grant such licenses to Augio on a
              limited, worldwide, non-exclusive, royalty-free, and fully paid
              basis.</p>
              <p className="terms-subheader">Conditions to Using Augio and Guidelines</p> 
              
              <p className="terms-subtext">Augio has a set of
              guidelines Users must follow in regard to use of the Platform and
              content uploaded to the Platform. This list is not exhaustive and
              is subject to change. Users shall not: 
              
              
              <ul>

              <li>Be under the age of 18,
              or if a User is 13 years old, that User has express permission
              from a parent or guardian to use Augio's Platform</li>
              <li>Attempt to
              register for an Augio account using a false name and/or
              unauthorized or fraudulent contact information</li>
              <li>Conduct, or
              attempt to conduct, any illegal, fraudulent, or prohibited
              activity using, including infringement of intellectual property or
              Augio's proprietary rights</li>
              <li>Be signed to a major record label.
              If found or reported to be signed to a major label, Users are
              subject to suspension and expulsion from the Platform</li>
              <li>Undertake
              any action that would violate any agreements or conditions of
              agreements with third parties, including agreements with
              independent record labels</li>
              <li>Upload any material or content that
              You are not authorized to upload</li>
              <li>Solicit or disclose personal,
              confidential, or proprietary information of third parties</li>
              <li>Engage in any harassment of Users on the Platform, including
              abusive, threatening, and defamatory acts</li>
              <li>Upload any
              unsolicited or unauthorized advertising, promotional messages,
              spam, or any other form of solicitation</li>
              <li>Share or provide links
              to subject matters that may be deemed offensive, including nudity
              and/or excessive violence</li>
              <li>Share malicious content such as
              malware, or viruses, or otherwise interferes with Augio's or any
              User's access to the Augio</li>
              <li>User must not employ any techniques
              or make use of any services, automated or otherwise, designed to
              misrepresent the popularity of Your Uploads on the Augio Platform,
              or to misrepresent Your activity on the Platform, including
              without limitation by the use of bots, botnets, scripts, apps,
              plugins, extensions or other automated means to register accounts,
              log in, add followers to Your account, follow or unfollow other
              users, send messages, post comments, or otherwise to act on Your
              behalf, particularly where such activity occurs in a multiple or
              repetitive fashion. Users must not offer or promote the
              availability of any such techniques or services to any other Users
              of the Augio</li>
              <li>Violate, circumvent, or attempt to violate or
              circumvent any data security measures employed by Augio or any
              Artist</li>
              <li>Access or attempt to access data or materials which are
              not intended for Your use</li>
              <li>Log into, or attempt to log into, a
              server or account which You are not authorized to access</li>
              <li>Attempt to scan or test the vulnerability of Augio's servers,
              system, or network, or attempt to breach Augio's data security or
              authentication procedures</li>
              <li>Attempt to interfere with the
              Website, the Platform, or the Services offered by Augio by any
              means including, without limitation, hacking Augio's servers or
              systems, submitting a virus, overloading, mail-bombing or
              crashing</li>
              <li>Employ scraping or similar techniques to aggregate,
              repurpose, republish or otherwise make use of any Content</li>
              <li>Alter
              or remove, or attempt to alter or remove, any trademark, copyright
              or other proprietary or legal notices contained in, or appearing
              on, the Platform or any content appearing on the Platform</li>
              <li>Use
              Augio in a manner inconsistent with any other terms or policies,
              or in a manner inconsistent with any applicable laws and
              regulations</li>
              </ul>
              
              </p>
              <p className="terms-subheader">Representations and Warranties</p> 
              
              <p className="terms-subtext">By accessing and uploading
              content to Augio, Users represent and warrant that (1) they have
              the full right and authority to post, upload or otherwise use any
              User Content (including any applicable third party rights and
              permissions), and to grant Augio the right to use User Content in
              accordance with these Terms; and (2) the User Content, including
              the use thereof by Users or Augio in accordance with these Terms,
              does not (a) violate these Terms; (b) infringe or otherwise
              violate any applicable laws, rules, regulations or third party
              rights (including any copyrights or rights of publicity or
              privacy), or (c) imply any affiliation, association or endorsement
              of You or Your User Content by Augio or any artist, band, label or
              other individual or entity without the express written consent of
              Augio or applicable third party; (3) Users have obtained any and
              all necessary consents, permissions and/or releases from any and
              all persons appearing in the User Content in order to include
              their name, voice, performance or likeness in User Content and to
              publish the same on Augio; (4) User Content does not and will not
              create any liability on the part of Augio, its subsidiaries,
              affiliates, successors, and assigns, and their respective
              employees, agents, directors, officers and/or shareholders. As put
              forth in other sections of these Terms, Augio reserves the right
              to remove User Content, suspend or terminate User access to the
              Platform and/or pursue all legal remedies if We believe that any
              of the User Content breaches any of the foregoing representations
              or warranties, or otherwise infringes another person's rights or
              violates any law, rule, or regulation.</p>
              <p className="terms-subheader">Third Party Services and Applications</p> 
              
              <p className="terms-subtext">Augio may provide
              Users with the opportunity to interact or access third party
              services, applications, websites, or platforms (“Third Parties”).
              These Third Parties have their own sets of terms and conditions,
              and privacy policy and Users may be subject to those terms when
              accessing the Third Parties. Augio is not responsible for any
              features, content, or privacy of Third Parties and Augio maintains
              no control over the operation of Third Parties. Augio in no manner
              warrants or represents the legality, accuracy, content, or
              services provided by Third Parties. It is the responsibility of
              the User to take the necessary precautions to avoid fraud,
              hacking, or other harmful content when interacting with Third
              Parties and Augio disclaims all liability.</p>
              <p className="terms-subheader">Reporting, Take Downs, Suspension, Termination</p>
              <p className="terms-subheader">Reporting</p>
              <p className="terms-subtext">Augio attempts to prevent any violations of these 
              Terms and the Guidelines, however it is not always possible that
              Our efforts find every violation. It is not mandatory for Users to
              act as reporters of any violations, however Users may report any
              violations of these Terms or the Guidelines here. Our support
              staff will review every report with the utmost scrutiny and take
              any action that We deem necessary to prevent a repeat occurrence.
              Without limitation to any other rights or remedies of Augio, We
              reserve the right to investigate any situation that appears to
              involve any of the Terms or Guidelines, and may report such
              matters to, and cooperate with, appropriate law enforcement
              authorities in prosecuting any Users who have contributed to any
              such violations.</p>
              <p className="terms-subheader">Take Downs</p> 
              
              <p className="terms-subtext">Augio has no legal obligation to monitor and
              screen User Content uploaded to the Platform, but reserves the
              right to block, remove, delete, or take down any uploaded content
              at any time, and to limit or restrict access to any content for
              any User, for any reason and without liability, including without
              limitation, if We have reason to believe that such content does or
              might infringe the rights of any third party, has been uploaded or
              posted in breach of these Terms, or applicable law, or is
              otherwise unacceptable to Augio.</p>
              <p className="terms-subheader">Suspension and/or Termination</p> 
              
              <p className="terms-subtext">Any User deemed to have
              violated these Terms or the guidelines will be suspended and Augio
              reserves the right to terminate the account and prevent the User
              from creating additional accounts on the Platform. Users will be
              sent an email notifying them of the suspension and/or termination
              and an explanation as to why Augio has taken action.</p>
              <p className="terms-subheader">Advertising</p> 
              
              <p className="terms-subtext">For Augio to remain available for Users, Augio
              may serve Users with targeted advertisements. We use information
              that You make available to us when You interact with the Platform
              to inform the nature of the ads, We show You and provide You with
              a customized experience. More information on how We use data to
              show You personalized ads is described in Our Privacy Policy.</p>
              <p className="terms-subheader">Updates to the Platform </p>
              
              <p className="terms-subtext">To provide Users with the best
              experience on the Platform, We may release new updates and
              features to the Platform, including Our Website and mobile
              application. Any new services and features will be subject to
              these Terms of Use as well as any additional terms and conditions
              that We may release for those specific services or features.</p>
              <p className="terms-subheader">Augio's Rights</p> 
              
              <p className="terms-subtext">Augio reserves all rights not expressly
              granted to You in this Agreement. Augio's Service and Augio's
              Content is the property of Augio. Access to and availability of
              certain Content on Augio, features Augio may vary from device to
              device, and may be affected by a variety of factors, such as User
              locations. Augio retains all right, title and interest in and to
              the Platform and Services and all copies thereof. Augio is the
              owner of the Augio Logo and other Augio trademarks (the
              “Trademarks”). Nothing in this Agreement or on the Augio Platform
              should be construed as granting, by implication, estoppel, or
              otherwise, any license or right to use the Trademarks, without
              Augio's prior written permission specific for each such use. Use
              of the Trademarks as part of a link to or from any site is
              prohibited unless establishment of such a link is approved in
              advance by Augio in writing. All goodwill generated from the use
              of the Trademark inures to Augio's benefit.</p>
              <p className="terms-subheader">Copyright</p> 
              
              <p className="terms-subtext">Pursuant to the Digital Millennium Copyright Act,
              17 U.S.C. Sec. 512, Augio will receive and respond to notices of
              claimed copyright infringement. If any User believes that their
              work has been copied and is accessible on the Platform in a way
              that constitutes copyright infringement, please use the form here
              to report the infringement. Augio will remove any content that
              infringes upon the copyright of any person under the laws of the
              United States upon receipt of such a statement (or, more
              specifically, any statement in conformance with 17 U.S.C. §
              512(c)(3)). United States law provides significant penalties for
              submitting such a statement falsely. Upon receipt of the written
              notification containing the requested information: (1) Augio may
              remove or disable access to the material that is alleged to be
              infringing; (2). Augio may forward the written notification to
              such alleged infringer; and (3). Augio may take reasonable steps
              to promptly notify the alleged infringer that it has removed or
              disabled access to the material.</p>
              <p className="terms-subheader">Disclaimer</p> 
              
              <p className="terms-subtext">THE AUGIO PLATFORM AND ITS CONTENT ARE PROVIDED
              ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF
              ANY KIND. AUGIO, ITS CONTENT OWNERS, SUBSIDIARIES, AFFILIATES,
              SUCCESSORS, AND ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS,
              DIRECTORS, OFFICERS, AND SHAREHOLDERS, TO THE FULLEST EXTENT
              PERMITTED BY APPLICABLE LAW, DISCLAIM ALL WARRANTIES, INCLUDING
              BUT NOT LIMITED TO THE WARRANTY OF TITLE, MERCHANTABILITY,
              NON-INFRINGEMENT OF THIRD PARTIES' RIGHTS, AND FITNESS FOR A
              PARTICULAR PURPOSE. AUGIO MAKES NO WARRANTIES OR REPRESENTATIONS
              ABOUT THE AUGIO PLATFORM OR ITS CONTENT, USER CONTENT, ANY
              THIRD-PARTY APPLICATIONS (OR ANY CONTENT CONTAINED THEREIN), OR
              ANY THIRD-PARTY PRODUCTS OR SERVICES ADVERTISED, PROMOTED, OR
              OFFERED BY A THIRD PARTY ON OR THROUGH THE AUGIO PLATFORM OR ANY
              HYPERLINKED WEBSITE, INCLUDING BUT NOT LIMITED TO THEIR ACCURACY,
              RELIABILITY, COMPLETENESS, EFFICACY, OR TIMELINESS. AUGIO DOES NOT
              WARRANT OR REPRESENT THAT YOUR USE OF THE AUGIO PLATFORM OR
              THIRD-PARTY SITES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
              ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE PLATFORM
              OR ANY PART OR PARTS THEREOF, THE CONTENT, OR THE SERVERS ON WHICH
              THE PLATFORM OPERATES ARE OR WILL BE FREE OF VIRUSES OR OTHER
              HARMFUL COMPONENTS. AUGIO SHALL NOT BE SUBJECT TO LIABILITY FOR
              THE TRUTH, ACCURACY, OR COMPLETENESS OF ANY AUGIO CONTENT OR USER
              CONTENT, INFORMATION CONVEYED TO YOU, OR FOR ERRORS, MISTAKES, OR
              OMISSIONS THEREIN, OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA
              OR INFORMATION STREAM FROM WHATEVER CAUSE. YOU AGREE THAT YOUR USE
              OF THE AUGIO SERVICE, AND THE AUGIO CONTENT AND ANY USER CONTENT,
              IS AT YOUR OWN RISK. THE AUGIO PLATFORM MAY PROVIDE YOU WITH THE
              ABILITY TO FILTER EXPLICIT CONTENT, BUT THESE FEATURES MAY STILL
              RESULT IN SOME EXPLICIT CONTENT BEING SERVED TO YOU. YOU SHOULD
              NOT RELY ON SUCH FEATURES TO FILTER ALL EXPLICIT CONTENT.</p>
              <p className="terms-subheader">Limitations of Liability and Dispute Resolution</p> 
              
              <p className="terms-subtext">IN NO EVENT
              SHALL AUGIO, ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND
              ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS,
              OFFICERS, AND SHAREHOLDERS BE LIABLE FOR (1) ANY SPECIAL,
              INCIDENTAL, DIRECT, INDIRECT, CONSEQUENTIAL, SPECIAL DAMAGES OR
              LOSS OF PROFIT ARISING FROM THE ACCESS, USE, INABILITY TO USE THE
              AUGIO SERVICE OR THE AUGIO CONTENT, ANY CHANGES AUGIO MAY
              IMPLEMENT ON THE PLATFORM, ANY SUSPENSION OR TERMINATION OF AN
              ACCOUNT, ANY ACTION TAKEN AGAINST YOU BY THIRD PARTYS WITH RESPECT
              TO INFRINGEMENT OF RIGHTS, ANY ERRORS OR OMISSIONS IN AUGIO'S
              TECHNICAL OPERATIONS OR DEFECT IN CONTENT; (2) ANY LOSS OR DAMAGE
              TO YOUR COMPUTER OR DEVICE, LOSS OF DATA, OR DAMAGE FROM SECRUITY
              BREACH; OR (3) ANY UNFORSEEABLE CIRCUMSTANCE SUFFERED BY YOU.
              NOTHING FOUND IN THESE TERMS WILL LIMIT OR EXCLUDE THE LIABILITY
              OF AUGIO, ITS SUBSIDIARIES, SUCCESSORS, ASSIGNS, OR THEIR
              RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, OFFICERS AND/OR
              SHAREHOLDERS IN REGARD TO (1) ANY DEATH OR PERSONAL INJURY CAUSED
              BY ITS OR THEIR NEGLIGENCE, (2) ANY FORM OF FRAUD OR DECEIT, (3)
              ANY DAMAGES CAUSED WILFULLY OR BY GROSS NEGLIGENCE, OR (4) ANY
              FORM OF LIABILITY WHICH CANNOT BE LIMITED OR EXCLUDED BY LAW. ANY
              CLAIM ARISING OUT OF THESE TERMS OR USE OF THE PLATFORM MUST BE
              SENT TO AUGIO IMMEDIATELY. NOTIFICATION OF CLAIMS MUST INCLUDE A
              DESCRIPTION OF THE DISPUTE OR CLAIM AS WELL AS INFORMATION THAT
              WOULD ALLOW AUGIO TO CONTACT YOU. THERE SHOULD BE A GOOD FAITH
              EFFORT MADE BY BOTH AUGIO AND YOU TO RESOLVE ANY ISSUES. IF AFTER
              SIXTY (60) DAYS AFTER AUGIO RECEIVES WRITTEN NOTIFICATION OF A
              CLAIM NO RESOLUTION IS REACHED, ALL DISPUTES SHALL BE RESOLVED BY
              ARBITRATION UNDER THE RULES AND AUSPICES OF THE AMERICAN
              ARBITRATION ASSOCIATION, TO BE HELD IN THE STATE OF NEW YORK, IN
              ENGLISH, WITH A WRITTEN DECISION STATING THE LEGAL REASONING
              ISSUED BY THE ARBITRATOR(S) AT EITHER PARTY'S REQUEST, AND WITH
              ARBITRATION FEES AND REASONABLE ATTORNEYS' FEES OF BOTH PARTIES TO
              BE BORNE BY THE PARTY THAT ULTIMATELY LOSES. AUGIO MAY OBTAIN
              INJUNCTIVE RELIEF (PRELIMINARY OR PERMANENT) AND ORDERS TO COMPEL
              ARBITRATION OR ENFORCE ARBITRAL AWARDS IN ANY COURT OF COMPETENT
              JURISDICTION.</p>
              <p className="terms-subheader">California Users</p> 
              
              <p className="terms-subtext">If You are a resident of California,
              effective January 1, 2020, You have certain rights under the
              California Consumer Privacy Act of 2018 (“CCPA”), including the
              following: (1) Know what categories of personal information Augio
              has collected and used, including how the personal information was
              collected and the purpose for the collection and use; (2) Know
              what categories of personal information are being sold or
              disclosed to third parties and, if sold or disclosed, the
              categories of third parties receiving it; (3) Access specific
              personal information collected about you; (4) Request that Your
              personal information be deleted. Augio may requests verification
              in order for Users to exercise rights under the CCPA Requests to
              exercise rights under the CCPA and are also subject to certain
              restrictions provided by the CCPA. If You are a California
              resident who wishes to exercise Your CCPA rights, please contact
              Augio at legal@augio.io.</p>
              <p className="terms-subheader">European Users</p> 
              
              <p className="terms-subtext">If You are accessing the Site from the
              European Union, Asia, or any other region with laws or regulations
              governing personal data collection, use, and disclosure, that
              differ from United States laws, then please note that You are
              transferring Your personal data to the United States which does
              not have the same data protection laws as the EU and other
              regions.</p>
              <p className="terms-subheader">Applicable Law</p> 
              
              <p className="terms-subtext">These Terms will be binding upon each party
              hereto and its successors and permitted assigns, and governed by
              and construed in accordance with the laws of the State of New York
              without reference to conflict of law principles. These Terms will
              not be assignable or transferable by you without the prior written
              consent of Augio. Augio may freely assign or transfer any rights
              granted by you to Augio under these Terms. These Terms (including
              all of the policies and other Agreements described in this Terms,
              which are hereby incorporated herein by this reference) contain
              the entire understanding of the parties regarding its subject
              matter, and supersedes all prior and contemporaneous agreements
              and understandings between the parties regarding its subject
              matter. No failure or delay by a party in exercising any right,
              power or privilege under these Terms will operate as a waiver
              thereof, nor will any single or partial exercise of any right,
              power or privilege preclude any other or further exercise thereof
              or the exercise of any other such right, power, or privilege. You
              and Augio are independent contractors, and no agency, partnership,
              joint venture, or employee-employer relationship is intended or
              created by these Terms. The invalidity or unenforceability of any
              provision of these Terms will not affect the validity or
              enforceability of any other provision of these Terms, all of which
              will remain in full force and effect. The headings used are for
              convenience only, do not constitute any part of these Terms, and
              shall not be deemed to limit or affect any of the provisions
              hereof.</p>
              <p className="terms-subheader">Indemnification</p> 
              
              <p className="terms-subtext">You will indemnify and hold Augio and its
              subsidiaries, affiliates, successors, and assigns, and their
              respective employees, agents, directors, officers, and
              shareholders (“the Parties”) harmless from (1) any damage, loss or
              expense (including attorneys' fees and costs) incurred in
              connection with any third-party claim, demand or action brought
              against any of the Parties alleging You have breached these Terms;
              (2) any third party claim of infringement of copyright or other
              intellectual property rights or invasion of privacy arising from
              the hosting of User Content on the Platform, and/or Your making
              available thereof to other Users of the Platform, and/or the
              actual use of User Content by other Users of the Platform or Third
              Parties in accordance with these Terms. If You are required to
              indemnify Augio, Augio will have the right to control the defense,
              settlement, and resolution of any Claim at Your sole expense. You
              may not settle or otherwise resolve any Claim without Augio's
              express written permission.</p>
            </p>

            <p className="terms-subheader">Copyright Infringement</p> 
            <p className="terms-subtext">
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and any or all Agreements:
              "Client", “You” and “Your” refers to you, the person accessing
              this website and accepting the Company's terms and conditions.
              "The Company", “Ourselves”, “We” and "Us", refers to our Company.
              To file a copyright infringement, click 
              <Link
                to="/copyright-infringement"
                className="copyrightinfringement-link"
              >
                Here
              </Link>
              . All terms refer to the offer, acceptance and consideration of
              payment necessary to undertake the process of our assistance to
              the Client in the most appropriate manner, whether by formal
              meetings of a fixed duration, or any other means, for the express
              purpose of meeting the Client's needs in respect of provision of
              the Company's stated services/products, in accordance with and
              subject to, prevailing English Law. Any use of the above
              terminology or other words in the singular, plural, capitalisation
              and/or he/she or they, are taken as interchangeable and therefore
              as referring to same.
            </p>
            © AUGIO 2024 All Rights Reserved
          
        </div>

        <footer>
        <div className="footer-entire-group">
          <div className="footer-links-group">
            <h6>Company</h6>
            <p className="footer-link">
              <HiOutlineMail className="footer-icon" />
              Press: info@augio.io
            </p>
            <p className="footer-link">
              <MdOutlineContactSupport className="footer-icon" />
              Support: support@augio.io
            </p>
          </div>
          <div className="footer-links-group">
            <h6>Quick Links</h6>
            {/*} <div className="footer-social-text footer-text-spacing">Contact</div>*/}
            <Link className="footer-link clickable-link"target="_blank" to="/terms">
              Terms
            </Link>
            <Link className="footer-link clickable-link" target="_blank" to="/membership-terms">
              Membership Agreement
            </Link>

            <Link className="footer-link clickable-link" target="_blank" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
          <div className="footer-links-group">
            <h6>Social</h6>

            <div className="social-link-row">
              <a
                className="footer-link clickable-link"
                href="https://www.twitter.com/augiomusic_io"
                target="_blank"
              >
                <BsTwitter className="footer-icon"></BsTwitter>
              </a>
              <p className="social-link-name">Twitter</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.instagram.com/augio.io"
                target="_blank"
              >
                <AiFillInstagram className="footer-icon"></AiFillInstagram>
              </a>
              <p className="social-link-name">Instagram</p>
            </div>
            <div className="social-link-row">
              <a
                className="footer-link"
                href="https://www.tiktok.com/@augio.io"
                target="_blank"
              >
                <FaTiktok className="footer-icon"></FaTiktok>
              </a>
              <p className="social-link-name">TikTok</p>
            </div>
          </div>
        </div>
        <div className="augio-copyright">© 2024 AUGIO LLC</div>
      </footer>
      </div>
    </div>
  );
};
